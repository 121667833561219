<template>
  <v-row>
    <DebugPage />
  </v-row>
</template>

<script>
import DebugPage from '@/components/features/DebugPage.vue'

export default {
  components: { DebugPage },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
