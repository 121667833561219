// This should be the same across all practice-types
const encounterCore = {
  id: null,
  is_validated: false,
  is_signed: false,
  is_synced: false,
  is_revised: false,
  is_quick_entry: false,
  is_quick_entry_validated: true,
  quick_entry_room: null,
  quick_entry_notes: null,
  quick_entry_notes_to_nurse: null,
  is_deferred_care: false,
  deferred_care_reason: null,
  visit_type: null,
  visit_location: null,
  signed_by_user_id: null,
  signed_time: null,
  addendums: [],
  encounter_attachments: [],
  communication_log_id: null,
  final_note: null,
}

// Specific to this practice type
const encounterInitial = {
  psychology: {
    appearance: null,
    orientation: null,
    memory: null,
    intellect: null,
    concentration: null,
    insight: null,
    judgement: null,
    behaviors: [],
    speeches: [],
    thought_flows: [],
    moods: [],
    affects: [],
    strengths: [],
    strengths_other: null,
    limitations: [],
    limitations_other: null,
    impaired_functions: [],
    impaired_functions_other: null,
    hallucinations: null,
    hallucinations_yes: null,
    delusions: null,
    delusions_yes: null,
    homicidal: null,
    homicidal_yes: null,
    suicidal: null,
    suicidal_yes: null,
    diagnosis: null,
    previous_diagnosis: null,
    diagnostic_impression: null,
    recommendations: null,
    frequency_of_follow_up_visits: null,
    estimated_duration: null,
    prognosis: null,
    treatment_modification_required_for: null,
    treatment_modification_required_for_yes: null,
    referral_source_statement_of_need: null,
    past_medical_history: null,
    past_psychiatry_history: null,
    relevant_non_psych_medications: null,
    current_psych_medications: null,
    adverse_reactions: null,
    adverse_reactions_yes: null,
    psychotropic_medication_reactions: null,
    psychotropic_medication_reactions_yes: null,
    substance_abuse_history: null,
    psychiatric_treatment_history: null,
    substance_abuse_treatment_history: null,
    substance_abuse_treatment_history_yes: null,
    recent_alcohol_and_drug_use: null,
    recent_alcohol_and_drug_use_yes: null,
    family_status: null,
    social_status: null,
    history_of_present_illness: null,
  },
}

export default {
  data() {
    return {
      encounter: {
        ...this.$lodash.cloneDeep(encounterCore),
        ...this.$lodash.cloneDeep(encounterInitial),
      },
      patient: {},

      // Below is specific to this practice type
      practiceType: 3,
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
}
