// This should be the same across all practice-types
const encounterCore = {
  id: null,
  is_validated: false,
  is_signed: false,
  is_synced: false,
  is_revised: false,
  is_quick_entry: false,
  is_quick_entry_validated: true,
  quick_entry_room: null,
  quick_entry_notes: null,
  quick_entry_notes_to_nurse: null,
  is_deferred_care: false,
  deferred_care_reason: null,
  visit_type: null,
  visit_location: null,
  signed_by_user_id: null,
  signed_time: null,
  addendums: [],
  encounter_attachments: [],
  communication_log_id: null,
  final_note: null,
}

// Specific to this practice type
const encounterInitial = {
  notes: null,
}

export default {
  data() {
    return {
      encounter: {
        ...this.$lodash.cloneDeep(encounterCore),
        ...this.$lodash.cloneDeep(encounterInitial),
      },
      patient: {},

      // Below is specific to this practice type
      practiceType: 2,
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
}
